($ => {

    const LeapMap = function () {

        return{
            markers: [],
            positions: [],
            locations: LeapMapPins.locations,
            clearAllMarkers() {
                this.markers.forEach(marker => {
                    marker.setMap(null);
                });
                this.positions = [];
                this.markers = [];
            },
            addMarkers(term = null) {
                if (this.locations.length) {
                    let locations = this.locations;
                    if (term) {
                        locations = locations.filter((location) => {
                            return location.term.term_id == term;
                        });
                    }
                    let timeout = 0;
                    locations.forEach((location) => {
                        this.addMarkerWithTimeout(location, timeout);
                        timeout += 5;
                    });
            }
            },
            addMarkerWithTimeout(location, timeout) {
                let pinLatLng = new google.maps.LatLng(location.pin_location);
                this.positions.push(pinLatLng);
                setTimeout(() => {
                    let icon = null;
                    if (typeof location.styles !== 'undefined') {
                        if (location.styles.icon) {
                            icon = {
                                url: location.styles.icon,
                                size: new google.maps.Size(location.styles.width, location.styles.height),
                                scaledSize: new google.maps.Size(24, location.styles.height / location.styles.width * 24),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(12, 12),
                            };
                        } else if (location.styles.colour) {
                            icon = {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: location.styles.colour,
                                fillOpacity: .9,
                                strokeColor: location.styles.colour,
                                scale: 7,
                            };
                        }
                    }
                    let infoWindow = new google.maps.InfoWindow({
                        content: location.content_string
                    });
                    infoWindow.addListener('closeclick', this.centerMap.bind(this));
                    let marker = this.addMarker(location, pinLatLng, icon, infoWindow);
                    this.markers.push(marker);
                }, timeout);
            },
            addMarker(location, latLng, icon, infoWindow) {
                let marker = new google.maps.Marker({
                    title: location.display_name,
                    ID: location.ID,
                    position: latLng,
                    map: this.map,
                    icon: icon,
                    animation: google.maps.Animation.DROP,
                });
                marker.infoWindow = infoWindow;
                google.maps.event.addListener(marker, 'click', (marker => {
                    return () => {
                        this.closeInfoWindows();
                        infoWindow.open(this.map, marker);
                    }
                })(marker));
                return marker;
            },
            openInfoWindow(post) {
                this.markers.forEach(marker => {
                    marker.infoWindow.close();
                    if (marker.ID == post) {
                        marker.infoWindow.open(this.map, marker);
                    }
                });
            },
            closeInfoWindows() {
                this.markers.forEach(marker => {
                    marker.infoWindow.close();
                });
            },
            centerMap() {
                let bounds = new google.maps.LatLngBounds();
                this.positions.forEach(position => {
                    bounds.extend(position);
                });
                this.map.fitBounds(bounds);
            },
            doMap() {
                const mapCanvas = document.getElementById('map-canvas');
                if (!mapCanvas) {
                    return;
                }
                this.map = new google.maps.Map(mapCanvas, {
                    zoom: LeapMapPins.map_position.zoom,
                    center: new google.maps.LatLng(LeapMapPins.map_position.lat, LeapMapPins.map_position.lng),
                    styles: LeapMapPins.styles,
                    mapTypeControl: false,
                    streetViewControl: false,
                    maxZoom: 15,
                    minZoom: 8
                });
                this.center = this.map.getCenter();
                this.clearAllMarkers();
                this.addMarkers();
                this.centerMap();
                google.maps.event.addDomListener(window, 'resize', this.centerMap.bind(this));
            }
        }
    };

    $(() => {

        window.leapMap = new LeapMap();

        if (window.google && window.google.maps) {
            leapMap.doMap();
        } else {
            var script = document.createElement('script');
            script.onload = leapMap.doMap.bind(leapMap);
            script.src = "https://maps.googleapis.com/maps/api/js?key=" + LeapMapPins.google_maps_api_key;
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        if (window.UIkit) {
            UIkit.util.on('#map_categories_menu', 'show', (e) => {
                let term = $(e.target).prev('a').data('term');
                leapMap.clearAllMarkers();
                leapMap.addMarkers(term);
                leapMap.centerMap();
            });
            $('#map_categories_menu').find('[data-post]').click(function (e) {
                e.preventDefault();
                let post = $(this).data('post');
                leapMap.openInfoWindow(post);
            });
        }
    })

})(jQuery)

